const students = [
    {
      id: "12345abcde",
      name: "John Doe",
      image: "/images/john_doe.jpg",
      pdf: "/certificate/12345abcde.pdf"
    },
    {
      id: "67890fghij",
      name: "Jane Smith",
      image: "/images/jane_smith.jpg",
      pdf: "/certificate/67890fghij.pdf"
    },
    // Add more students here...
  ];
  
  export default students;
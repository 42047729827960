import React, { useState } from "react";
import { useNavigate, Routes, Route, useParams } from "react-router-dom";
import students from "./students"; // Your existing students data array
import CertificatePage from "./certificatePage"; // Keep your existing CertificatePage

// SearchPage component for the search functionality
const SearchPage = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    const student = students.find((student) => student.id === query.trim());
    if (student) {
      navigate(`/certificate/${student.id}`);
    } else {
      alert("No certificate found for this ID.");
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Certificate Lookup</h1>
      <input
        type="text"
        placeholder="Enter unique certificate ID"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        style={{ padding: "10px", fontSize: "16px", width: "300px" }}
      />
      <button
        onClick={handleSearch}
        style={{ padding: "10px 20px", marginLeft: "10px" }}
      >
        Search
      </button>
    </div>
  );
};

// Wrapper to extract the id from URL and pass it to CertificatePage
const CertificateWrapper = () => {
  const { id } = useParams();
  return <CertificatePage id={id} />;
};

// App component for handling routing
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path="/certificate/:id" element={<CertificateWrapper />} />
    </Routes>
  );
};

export default App;

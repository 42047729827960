import React from "react";
import { useParams } from "react-router-dom";
import students from "./students";

const CertificatePage = () => {
  const { id } = useParams();
  const student = students.find((student) => student.id === id);

  if (!student) {
    return <h2>No certificate found</h2>;
  }

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Certificate for {student.name}</h1>
      <img
        src={student.image}
        alt={student.name}
        style={{ width: "150px", height: "150px", borderRadius: "50%" }}
      />
      <div style={{ marginTop: "20px" }}>
        <iframe
          src={student.pdf}
          title="Certificate PDF"
          width="80%"
          height="600px"
          style={{ border: "1px solid #ccc" }}
          onError={(e) => {
            e.target.src = "";
            alert("Failed to load PDF. Please try again or download.");
          }}
        ></iframe>
      </div>
      <a
        href={student.pdf}
        download
        style={{
          marginTop: "20px",
          display: "inline-block",
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "#fff",
          textDecoration: "none",
          borderRadius: "5px",
        }}
      >
        Download PDF
      </a>
    </div>
  );
};

export default CertificatePage;
